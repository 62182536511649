import * as React from 'react';
import styles from './tl.module.css';
import PostList from '../list/list';
import Layout from '../layout';
import { BlogPostItem, Tag, Topic } from '../Types';
import Tags from '../tags';
import { Helmet } from 'react-helmet';

const Routes = require('../../routes').Routes;

interface Props {
  pathContext: {
    posts: ReadonlyArray<BlogPostItem>;
    tag: Tag;
    tags: ReadonlyArray<Tag>;
    topics: ReadonlyArray<Topic>;
  };
  location: {
    pathname: string;
  };
}

function TaggedList({ pathContext }: Props) {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://brocoders.com/blog/" />
      </Helmet>
      <Layout topics={pathContext.topics} tag={pathContext.tag}>
        <section className="section">
          <div className="inner">
            <div className={styles.top}>
              <h2 className={styles.title}>
                Tagged in:
                <div className={styles.current}>{pathContext.tag.title}</div>
              </h2>
              <Tags list={pathContext.tags} className={styles.tags} />
            </div>
            <ul className={styles.list}>
              <PostList list={pathContext.posts} pathname={Routes.BLOG} />
            </ul>
            {/*<div className={styles.btnGroup}>*/}
            {/*  <button*/}
            {/*    className={clsx('btn btn_60 btn__black btn__black_red', styles.btn)}*/}
            {/*    type="button"*/}
            {/*  >Load More</button>*/}
            {/*</div>*/}
          </div>
        </section>
      </Layout>
    </>
  );
}

export default TaggedList;
